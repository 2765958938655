<template>
  <div class="LdFilterCmp">
    <Pulse-Loader
      :loading="loading"
      style="
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100vh;
        margin-top: 25%;
      "
    ></Pulse-Loader>
    <!-- Botão de Logout -->
    <a href="/">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="logout"
        viewBox="0 0 172 172"
        style="fill: #000000"
      >
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <!--<g fill="#006752">-->
          <g fill="#4a923e">
            <path
              d="M41.20833,21.5c-10.82086,0 -19.70833,8.88748 -19.70833,19.70833v89.58333c0,10.82086 8.88748,19.70833 19.70833,19.70833h64.5c10.82086,0 19.70833,-8.88748 19.70833,-19.70833c0.02741,-1.93842 -0.99102,-3.74144 -2.66532,-4.71865c-1.6743,-0.97721 -3.74507,-0.97721 -5.41937,0c-1.6743,0.97721 -2.69273,2.78023 -2.66532,4.71865c0,5.01031 -3.94802,8.95833 -8.95833,8.95833h-64.5c-5.01031,0 -8.95833,-3.94802 -8.95833,-8.95833v-89.58333c0,-5.01031 3.94802,-8.95833 8.95833,-8.95833h64.5c5.01031,0 8.95833,3.94802 8.95833,8.95833c-0.02741,1.93842 0.99102,3.74144 2.66532,4.71865c1.6743,0.97721 3.74507,0.97721 5.41937,0c1.6743,-0.97721 2.69273,-2.78023 2.66532,-4.71865c0,-10.82086 -8.88748,-19.70833 -19.70833,-19.70833zM119.98568,55.48568c-2.18814,0.00053 -4.1576,1.32735 -4.98006,3.35504c-0.82245,2.0277 -0.33375,4.35156 1.23575,5.87624l15.90804,15.90804h-76.60775c-1.93842,-0.02741 -3.74144,0.99102 -4.71865,2.66532c-0.97721,1.6743 -0.97721,3.74507 0,5.41937c0.97721,1.6743 2.78023,2.69273 4.71865,2.66532h76.60775l-15.90804,15.90804c-1.40412,1.34815 -1.96971,3.35005 -1.47866,5.23364c0.49105,1.88359 1.96202,3.35456 3.84561,3.84561c1.88359,0.49105 3.88549,-0.07455 5.23364,-1.47866l25.08333,-25.08333c2.09823,-2.0991 2.09823,-5.50149 0,-7.60059l-25.08333,-25.08333c-1.01222,-1.0424 -2.4033,-1.63064 -3.85628,-1.6307z"
            ></path>
          </g>
        </g>
      </svg>
    </a>

    <div class="organizacao" style="margin: 5% auto">
      <div class="teste fadeInFilter">
        <div ref="formContent">
          <div id="formChanges">
            <!-- Icon -->
            <div class="fadeIn first">
              <img src="img/logo_klein.jpg" id="icon" alt="Lab Logo" />
            </div>
            <br />
            <hr />
            <br />

            <!-- Sessão de Botões -->
            <input
              type="submit"
              class="fadeIn sixth"
              id="button"
              value="Buscar Laudos"
              ref="btnSrcRes"
              v-on:click="getLaudos"
            />
            <!--<input
            type="submit"
            class="fadeIn sixth"
            id=""
            value="Cadastrar paciente"
            ref="btnRegPat"
            v-on:click="submitPatient"
          />
          <input
            type="submit"
            class="fadeIn sixth"
            id=""
            value="Registrar solicitação"
            ref="btnRegSol"
            v-on:click="getSolicitacao"
          />-->
          </div>

          <!-- Blocos de Conteúdos -->
          <div id="formContents" ref="laudos">
            <!-- Filter Form -->
            <form class="consultaLaudo" @submit.prevent="searchLabRes">
              <input
                type="text"
                id="name"
                class="fadeIn second"
                name="name"
                placeholder="Nome"
                ref="nomePaciente"
              />
              <div class="calendario">
                <datepicker
                  id="from"
                  v-model="dateIni"
                  type="date"
                  lang="pt-br"
                  placeholder="Período de"
                  class="fadeIn second"
                  ref="dataInicio"
                  required
                ></datepicker>
                <datepicker
                  id="to"
                  v-model="dateEnd"
                  type="date"
                  lang="pt-br"
                  placeholder="Período até"
                  class="fadeIn second"
                  ref="dataFinal"
                  required
                ></datepicker>
              </div>
              <input
                type="text"
                id="laudo"
                class="fadeIn fourth"
                name="laudo"
                placeholder="Laudo"
                ref="nLaudo"
              />
              <input
                type="text"
                id="filial"
                class="fadeIn fifth"
                name="filial"
                placeholder="Filial"
                ref="filial"
                hidden
              />
              <input type="submit" class="fadeIn sixth" value="Buscar" />
            </form>
          </div>

          <div id="formPaciente" ref="paciente">
            <form class="cadastrarPaciente">
              <input
                type="text"
                name="nome"
                placeholder="Nome Completo"
                required
              />
              <div class="calendario">
                <datepicker
                  id="from"
                  v-model="dateIni"
                  type="date"
                  lang="pt-br"
                  placeholder="Data de Nasc."
                  class="fadeIn second"
                  required
                ></datepicker>
              </div>
              <select required @change="alteraCampo($event)">
                <option value="" ref="nomeCampo">Sexo</option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </select>
              <input
                type="text"
                placeholder="CPF"
                maxlength="14"
                v-mask="'###.###.###-##'"
                v-model="fcpf"
                required
              />
              <select required>
                <option value="">Cor</option>
                <option value="1">Branca</option>
                <option value="2">Negra</option>
                <option value="3">Parda</option>
                <option value="4">Amarela</option>
                <option value="5">Indígena</option>
                <option value="6">Não informar</option>
              </select>
              <input
                type="submit"
                class="fadeIn sixth"
                value="Enviar"
                v-on:click="submitPatientForm"
              />
            </form>
          </div>

          <div id="formSolicitacao" ref="solicitacao">
            <form class="regSolicitacao">
              <div class="sel-patient">
                <select
                  style="float: left"
                  id="inputGroupSelectPatient"
                  ref="sr_patSelecInp"
                  required
                >
                  <option value="">Selecione o paciente</option>
                </select>
                <div class="input-group-append">
                  <button
                    class="fadeIn sixth"
                    type="button"
                    data-toggle="modal"
                    data-target="#search-pat"
                  >
                    ?
                  </button>
                </div>
              </div>
              <p>
                Não encontrou o paciente?<button
                  type="button"
                  class="fadeIn sixth"
                  v-on:click="submitPatient"
                >
                  clique aqui</button
                >para adicionar.
              </p>
              <div class="calendario">
                <datepicker
                  id="from"
                  v-model="dateIni"
                  type="date"
                  lang="pt-br"
                  placeholder="Data de Nasc."
                  class="fadeIn second"
                  required
                ></datepicker>
              </div>
              <select required @change="alteraCampo($event)">
                <option value="" ref="nomeCampo">Sexo</option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </select>
              <input
                type="text"
                placeholder="CPF"
                maxlength="14"
                v-mask="'###.###.###-##'"
                v-model="fcpf"
                required
              />
              <select required>
                <option value="">Cor</option>
                <option value="1">Branca</option>
                <option value="2">Negra</option>
                <option value="3">Parda</option>
                <option value="4">Amarela</option>
                <option value="5">Indígena</option>
                <option value="6">Não informar</option>
              </select>
              <input
                type="submit"
                class="fadeIn sixth"
                value="Enviar"
                v-on:click="submitPatient"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../public/img/menu.svg"
      class="botaoMenu"
      v-on:click="getMenu"
    />
    <section id="menuMobile" ref="menuMobile">
      <!-- Botão de Fechar Menu -->
      <a class="fecharMenu" v-on:click="fechaMenu"> &times; </a>

      <!-- Logotipo -->
      <div class="logoMenu">
        <img
          src="img/logo_klein.jpg"
          id="icon"
          alt="Lab Logo"
          style="object-fit: cover"
        />
      </div>

      <!-- Logout -->
      <a href="LdFilterCmp.vue">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="logoutMobile"
          viewBox="0 0 172 172"
          style="fill: #000000"
        >
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <path d="M0,172v-172h172v172z" fill="none"></path>
            <!--<g fill="#006752">-->
            <g fill="4a923e">
              <path
                d="M41.20833,21.5c-10.82086,0 -19.70833,8.88748 -19.70833,19.70833v89.58333c0,10.82086 8.88748,19.70833 19.70833,19.70833h64.5c10.82086,0 19.70833,-8.88748 19.70833,-19.70833c0.02741,-1.93842 -0.99102,-3.74144 -2.66532,-4.71865c-1.6743,-0.97721 -3.74507,-0.97721 -5.41937,0c-1.6743,0.97721 -2.69273,2.78023 -2.66532,4.71865c0,5.01031 -3.94802,8.95833 -8.95833,8.95833h-64.5c-5.01031,0 -8.95833,-3.94802 -8.95833,-8.95833v-89.58333c0,-5.01031 3.94802,-8.95833 8.95833,-8.95833h64.5c5.01031,0 8.95833,3.94802 8.95833,8.95833c-0.02741,1.93842 0.99102,3.74144 2.66532,4.71865c1.6743,0.97721 3.74507,0.97721 5.41937,0c1.6743,-0.97721 2.69273,-2.78023 2.66532,-4.71865c0,-10.82086 -8.88748,-19.70833 -19.70833,-19.70833zM119.98568,55.48568c-2.18814,0.00053 -4.1576,1.32735 -4.98006,3.35504c-0.82245,2.0277 -0.33375,4.35156 1.23575,5.87624l15.90804,15.90804h-76.60775c-1.93842,-0.02741 -3.74144,0.99102 -4.71865,2.66532c-0.97721,1.6743 -0.97721,3.74507 0,5.41937c0.97721,1.6743 2.78023,2.69273 4.71865,2.66532h76.60775l-15.90804,15.90804c-1.40412,1.34815 -1.96971,3.35005 -1.47866,5.23364c0.49105,1.88359 1.96202,3.35456 3.84561,3.84561c1.88359,0.49105 3.88549,-0.07455 5.23364,-1.47866l25.08333,-25.08333c2.09823,-2.0991 2.09823,-5.50149 0,-7.60059l-25.08333,-25.08333c-1.01222,-1.0424 -2.4033,-1.63064 -3.85628,-1.6307z"
              ></path>
            </g>
          </g>
        </svg>
      </a>
      <hr />

      <!-- Sessões de Botões -->
      <input
        id="button"
        type="submit"
        class="fadeIn sixth"
        value="Buscar Laudos"
        v-on:click="getLaudosMobile"
      />
      <!--<input
        template
        id="button"
        type="submit"
        class="fadeIn sixth"
        value="Cadastrar paciente"
        v-on:click="getPacienteMobile"
      />
      <input
        id="button"
        type="submit"
        class="fadeIn sixth"
        value="Registrar solicitação"
        v-on:click="getSolicitacaoMobile"
      />-->
    </section>
    <!-- Modal -->
    <div
      class="modal fade"
      id="search-pat"
      tabindex="-1"
      role="dialog"
      aria-labelledby="search-pat"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Pesquisar Paciente
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-container">
              <div data-toggle="validator" data-focus="false">
                <div class="form-group">
                  <input
                    type="text"
                    name="nome"
                    placeholder="Nome Completo"
                    v-on:keyup.enter="get_patient()"
                    required
                  />
                  <div class="help-block with-errors"></div>
                </div>
                <div class="form-group">
                  <div class="calendario">
                    <datepicker
                      id="from"
                      v-model="dateIni"
                      type="date"
                      lang="pt-br"
                      placeholder="Data de Nasc."
                      class="fadeIn second"
                      required
                    ></datepicker>
                  </div>
                  <div class="help-block with-errors"></div>
                </div>
              </div>
            </div>
            <div class="div-loading">
              <button class="btn-return" ref="s_btnLoading" hidden>
                <span class="spinner-border spinner-border-sm"></span>
              </button>
            </div>
            <hr class="ht-act" />
            <ul id="lst-pacientes">
              <li v-for="pac in s_pacientes">
                <a
                  href="#"
                  v-on:click="
                    set_patient(
                      pac.IDPACIENTE,
                      pac.IDFILIALPAC,
                      pac.PACNOME,
                      pac.PACIDENT,
                      pac.PACNOMETIT,
                      pac.PACEMAIL,
                      pac.PACDTNASC,
                      pac.PACSEXO,
                      pac.PACCEP,
                      pac.PACENDERECO,
                      pac.PACENDERECONUMERO,
                      pac.PACBAIRRO,
                      pac.PACUF,
                      pac.PACCIDADE,
                      pac.IDTIPOCOR,
                      pac.TCRDESC,
                      pac.IDPAIS_PAC,
                      pac.COD_UF,
                      pac.COD_MUNICIPIO,
                      pac.PACPRONTUARIO
                    )
                  "
                  v-bind:id="pac.IDPACIENTE"
                >
                  {{ pac.PACNOME }} || {{ pac.PACCPF }}</a
                >
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn-solid-reg-close"
              data-dismiss="modal"
              ref="s_pat_btnClose"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn-solid-reg"
              v-on:click="get_patient()"
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "LdFilterCmp",
  components: {
    Datepicker: VueDatepickerUi,
    PulseLoader,
  },
  data: () => ({
    loading: false,
    dateIni: "",
    dateEnd: "",
    fcpf: "",
    s_pacientes: [],
  }),
  computed: {
    state() {
      return this.$store.state;
    },
    laudos() {
      return this.$store.laudos;
    },
    acoes() {
      return this.$actions;
    },
  },
  mounted: function () {
    // Button BUSCAR LAUDOS
    this.$refs.btnSrcRes.style.color = "#009a63";
    this.$refs.btnSrcRes.style.backgroundColor = "#fff";
    this.$refs.btnSrcRes.style.border = "1px solid #009a63";

    let _this = this;
    if (_this.state.lType === "tulPaciente" || _this.state.lType === "tulPacienteCpf") {
      _this.acoes.clearAll(_this.state);
      _this.$router.push("/");
    }
  },
  methods: {
    searchLabRes(e) {
      e.preventDefault();

      let _this = this;
      _this.$refs.formContent.style.opacity = "0.4";
      let nomePaciente = this.$refs.nomePaciente.value;
      let dataInicio = this.$refs.dataInicio.value;
      let dataFinal = this.$refs.dataFinal.value;
      let nLaudo = this.$refs.nLaudo.value;
      var filial = null;
      if (_this.state.lType === "tulCentralAtendimento")
        filial = _this.state.idd;

      var hasLaudo = false;
      if (
        dataInicio === "" ||
        dataInicio === undefined ||
        dataFinal === undefined ||
        dataFinal === ""
      ) {
        if (nLaudo != "" && nLaudo != undefined) {
          hasLaudo = true;
        } else {
          setTimeout(() => {
            _this.$notify({
              group: "alerts",
              type: "warn",
              title: "ATENCAO",
              text: "É necessário informar o periodo de busca.",
            });
          }, 100);
          _this.$refs.formContent.style.opacity = "1";
          return;
        }
      }

      var dti = '';
      var dtf = '';
      if (!hasLaudo) {
        var dtIni = dataInicio;
        dti = dtIni.toISOString();
        dti = dti.slice(0, dti.length - 5);
        dti = dti.replace("T", " ");
        dti = dti.split(" ")[0];

        var dtFim = dataFinal;
        dtf = dtFim.toISOString();

        dtf = dtf.slice(0, dtf.length - 5);
        dtf = dtf.replace("T", " ");
        dtf = dtf.split(" ")[0];
      }
      this.loading = true;
      var ac = _this.acoes;
      axios
        .get(
          "/v1/res" +
            "?COD24=" +
            _this.state.id +
            "&COD106=" +
            _this.state.lType +
            "&COD01=" +
            nomePaciente +
            "&COD21=" +
            dti +
            "&COD22=" +
            dtf +
            "&COD09=" +
            nLaudo +
            "&COD23=" +
            filial
        )
        .then(function (response) {
          if (response.status === 200) {
            ac.setLaudos(response.data.result);
            _this.$router.push("/mdy-list");
          } else {
            _this.$refs.formContent.style.opacity = "1";
          }
        })
        .catch(function (error) {
          setTimeout(() => {
            _this.$notify({
              group: "alerts",
              type: "error",
              title: "ATENCAO",
              text: "Nenhum resultado encontrado para o periodo informado.",
            });
          }, 100);
          _this.$refs.formContent.style.opacity = "1";
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLaudo() {
      this.loading = true;
      setTimeout(() => {}, 1000);
    },
    getMenu() {
      this.$refs.menuMobile.style.marginRight = "0%";
    },
    fechaMenu() {
      this.$refs.menuMobile.style.marginRight = "-100%";
    },
    //Animação Buscar Laudos - Mobile
    getLaudosMobile() {
      this.$refs.paciente.style.opacity = "0";
      this.$refs.solicitacao.style.opacity = "0";
      this.$refs.menuMobile.style.marginRight = "-100%";

      var self = this;

      setTimeout(function () {
        self.$refs.laudos.style.opacity = "1";
        self.$refs.paciente.style.display = "none";
        self.$refs.solicitacao.style.display = "none";
      }, 1500);

      setTimeout(function () {
        self.$refs.laudos.style.display = "block";
      }, 1460);
    },

    //Animação Cadastrar Paciente - Mobile
    getPacienteMobile() {
      this.$refs.laudos.style.opacity = "0";
      this.$refs.solicitacao.style.opacity = "0";
      this.$refs.menuMobile.style.marginRight = "-100%";

      var self = this;

      setTimeout(function () {
        self.$refs.laudos.style.display = "none";
        self.$refs.paciente.style.opacity = "1";
        self.$refs.solicitacao.style.display = "none";
      }, 1500);

      setTimeout(function () {
        self.$refs.paciente.style.display = "block";
      }, 1460);
    },

    //Animação Registrar Solicitação - Mobile
    getSolicitacaoMobile() {
      this.$refs.laudos.style.opacity = "0";
      this.$refs.paciente.style.opacity = "0";
      this.$refs.menuMobile.style.marginRight = "-100%";

      var self = this;

      setTimeout(function () {
        self.$refs.laudos.style.display = "none";
        self.$refs.paciente.style.opacity = "none";
        self.$refs.solicitacao.style.opacity = "1";
      }, 1500);

      setTimeout(function () {
        self.$refs.solicitacao.style.display = "block";
      }, 1460);
    },

    //Animação Buscar Laudos
    getLaudos() {
      let w = window.innerWidth;
      let h = window.innerHeight;

      // Button BUSCAR LAUDOS
      this.$refs.btnSrcRes.style.color = "#fff";
      this.$refs.btnSrcRes.style.backgroundColor = "#4a923e";
      this.$refs.btnSrcRes.style.border = "1px solid #4a923e";

      // Button REGISTRAR SOLICITACAO
      this.$refs.btnRegSol.style.color = "white";
      this.$refs.btnRegSol.style.backgroundColor = "#4a923e";
      this.$refs.btnRegSol.style.border = "none";

      // Button CADASTRAR PACIENTE
      this.$refs.btnRegPat.style.color = "white";
      this.$refs.btnRegPat.style.backgroundColor = "#4a923e";
      this.$refs.btnRegPat.style.border = "none";

      if (w > 799 && w < 998) {
        this.$refs.paciente.style.marginLeft = "-360px";
        this.$refs.paciente.style.opacity = "0";
        this.$refs.solicitacao.style.marginLeft = "-350px";
        this.$refs.solicitacao.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.laudos.style.opacity = "1";
          self.$refs.laudos.style.marginLeft = "350px";
          self.$refs.paciente.style.display = "none";
          self.$refs.solicitacao.style.display = "none";
        }, 1500);

        setTimeout(function () {
          self.$refs.laudos.style.display = "block";
        }, 1460);
      } else if (w > 999) {
        this.$refs.paciente.style.marginLeft = "-225px";
        this.$refs.paciente.style.opacity = "0";
        this.$refs.solicitacao.style.marginLeft = "-225px";
        this.$refs.solicitacao.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.laudos.style.opacity = "1";
          self.$refs.laudos.style.marginLeft = "0px";
          self.$refs.paciente.style.display = "none";
          self.$refs.solicitacao.style.display = "none";
        }, 1500);
        setTimeout(function () {
          self.$refs.laudos.style.display = "block";
        }, 1460);
      }
    },

    //Animação Cadastrar Paciente
    submitPatient() {
      let w = window.innerWidth;
      let h = window.innerHeight;

      // Button CADASTRAR PACIENTE
      this.$refs.btnRegPat.style.color = "#4a923e";
      this.$refs.btnRegPat.style.backgroundColor = "#fff";
      this.$refs.btnRegPat.style.border = "1px solid #4a923e";

      // Button REGISTRAR SOLICITACAO
      this.$refs.btnRegSol.style.color = "white";
      this.$refs.btnRegSol.style.backgroundColor = "#4a923e";
      this.$refs.btnRegSol.style.border = "none";

      // Button BUSCAR LAUDOS
      this.$refs.btnSrcRes.style.color = "white";
      this.$refs.btnSrcRes.style.backgroundColor = "#4a923e";
      this.$refs.btnSrcRes.style.border = "none";

      if (w > 799 && w < 998) {
        this.$refs.laudos.style.marginLeft = "-25px";
        this.$refs.laudos.style.opacity = "0";
        this.$refs.solicitacao.style.marginLeft = "-350px";
        this.$refs.solicitacao.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.paciente.style.opacity = "1";
          self.$refs.paciente.style.marginLeft = "0px";
          self.$refs.laudos.style.display = "none";
          self.$refs.solicitacao.style.display = "none";
        }, 1500);

        setTimeout(function () {
          self.$refs.paciente.style.display = "block";
        }, 1460);
      } else if (w > 999) {
        this.$refs.laudos.style.marginLeft = "-450px";
        this.$refs.laudos.style.opacity = "0";
        this.$refs.solicitacao.style.marginLeft = "-225px";
        this.$refs.solicitacao.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.paciente.style.opacity = "1";
          self.$refs.paciente.style.marginLeft = "225px";
          self.$refs.laudos.style.display = "none";
          self.$refs.solicitacao.style.display = "none";
        }, 1500);
        setTimeout(function () {
          self.$refs.paciente.style.display = "block";
        }, 1460);
      }
    },

    //Animação Registrar Solicitação
    getSolicitacao() {
      let w = window.innerWidth;
      let h = window.innerHeight;

      // Button REGISTRAR SOLICITACAO
      this.$refs.btnRegSol.style.color = "#4a923e";
      this.$refs.btnRegSol.style.backgroundColor = "#fff";
      this.$refs.btnRegSol.style.border = "1px solid #4a923e";

      // Button BUSCAR LAUDOS
      this.$refs.btnSrcRes.style.color = "white";
      this.$refs.btnSrcRes.style.backgroundColor = "#4a923e";
      this.$refs.btnSrcRes.style.border = "none";

      // Button CADASTRAR PACIENTE
      this.$refs.btnRegPat.style.color = "white";
      this.$refs.btnRegPat.style.backgroundColor = "#4a923e";
      this.$refs.btnRegPat.style.border = "none";

      if (w > 799 && w < 998) {
        this.$refs.laudos.style.marginLeft = "-25px";
        this.$refs.laudos.style.opacity = "0";
        this.$refs.paciente.style.marginLeft = "-350px";
        this.$refs.paciente.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.solicitacao.style.opacity = "1";
          self.$refs.solicitacao.style.marginLeft = "0px";
          self.$refs.laudos.style.display = "none";
          self.$refs.paciente.style.display = "none";
        }, 1500);
        setTimeout(function () {
          self.$refs.solicitacao.style.display = "block";
        }, 1460);
      } else if (w > 999) {
        this.$refs.laudos.style.marginLeft = "-450px";
        this.$refs.laudos.style.opacity = "0";
        this.$refs.paciente.style.marginLeft = "-225px";
        this.$refs.paciente.style.opacity = "0";

        var self = this;

        setTimeout(function () {
          self.$refs.solicitacao.style.opacity = "1";
          self.$refs.solicitacao.style.marginLeft = "225px";
          self.$refs.laudos.style.display = "none";
          self.$refs.paciente.style.display = "none";
        }, 1500);

        setTimeout(function () {
          self.$refs.solicitacao.style.display = "block";
        }, 1460);
      }
      this.populate_patient_select_inp();
    },
    alteraCampo: function (event) {
      this.$refs.nomeCampo.disabled = "TRUE";
    },
    create_pat_opt(pat, index) {
      let _this = this;
      var pat_selec_inp = _this.$refs.sr_patSelecInp;

      var op = document.createElement("option");

      op.value = index;
      op.innerText = pat["PACNOME"];
      op.id = pat["IDPACIENTE"];
      op.setAttribute("data-p1", pat["PACIDENT"]);
      op.setAttribute("data-p2", pat["PACNOMETIT"]);
      op.setAttribute("data-p3", pat["PACEMAIL"]);
      op.setAttribute("data-p4", pat["PACDTNASC"]);
      op.setAttribute("data-p5", pat["PACSEXO"]);
      op.setAttribute("data-p6", pat["PACCEP"]);
      op.setAttribute("data-p7", pat["PACENDERECO"]);
      op.setAttribute("data-p8", pat["PACENDERECONUMERO"]);
      op.setAttribute("data-p9", pat["PACBAIRRO"]);
      op.setAttribute("data-p10", pat["PACCIDADE"]);
      op.setAttribute("data-p11", pat["PACUF"]);
      op.setAttribute("data-p12", pat["IDTIPOCOR"]);
      op.setAttribute("data-p13", pat["TCRDESC"]);
      op.setAttribute("data-p14", pat["IDPAIS_PAC"]);
      op.setAttribute("data-p15", pat["IDFILIALPAC"]);
      op.setAttribute("data-p16", pat["COD_UF"]);
      op.setAttribute("data-p17", pat["COD_MUNICIPIO"]);
      op.setAttribute("data-p18", pat["PACPRONTUARIO"]);
      pat_selec_inp.add(op);
    },
    populate_patient_select_inp() {
      let _this = this;
      var pats = [];

      if ($("#inputGroupSelectPatient")[0].options.length < 2) {
        axios
          .get("http://feedline-suporte.no-ip.com:3000/v1/all/patients")
          .then(function (response) {
            pats = response.data;
            $("#inputGroupSelectPatient")
              .find("option")
              .remove()
              .end()
              .append('<option value="">Selecione o Paciente...</option>')
              .val("");
            pats.forEach(_this.create_pat_opt);
          });
      }
    },
    submitPatientForm() {
      let _this = this;
      var pacNome = "dwdw";
      var pacCPF = "123123123";
      var pacDtNasc = "01-02-1992";
      var pacSexo = "M";
      var pacIdTpCor = 1;
      var pacCorDesc = "M";

      var json = {
        COD01: pacNome,
        COD02: pacCPF,
        COD03: pacDtNasc,
        COD04: pacSexo,
        COD05: pacCorDesc,
        COD06: pacIdTpCor,
      };

      axios
        .post("http://feedline-suporte.no-ip.com:3000/v1/paciente", json)
        .then(function (response) {
          if (response.status === 201) {
            _this.$router.push("/mdy-filter");
          } else {
            setTimeout(() => {
              _this.$notify({
                group: "alerts",
                type: "warn",
                title: "ATENCAO",
                text: response.message,
              });
            }, 100);
          }
        })
        .catch(function (error) {
          setTimeout(() => {
            _this.$notify({
              group: "alerts",
              type: "error",
              title: "ATENCAO",
              text: "...",
            });
          }, 100);
        });
    },
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
